$standard-button: 200px;
$standard-large-font: 25px; 
$standard-nav-btn: rgb(25, 87, 87);
$secondary-color: rgb(33, 163, 163);
$input-height: 60px;

.action-content{
    height:100%;
    min-width:100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

}

.insert-input-box{
    display: flex;
    flex-direction: rows;
    padding-top:50px;
    padding-bottom:50px;
    background-color:rgb(220, 222, 235);
    align-items: center;
    justify-content: center;
}

.estimates-box{
    width:100%;
}

.nav-btn{
    background-color: $secondary-color;
    border:0;
}


.program-item-box{
    padding:10px;
    max-width: 100%;
    margin: 0 auto;
}

.estimate-items-box{
    display: flex;
    flex-direction: rows;
    width:100%;
    padding:40px;
}

.estimate-row{
    width:100%;
    font-size: 20px;
    padding:5px;
}
.item-opt{
    width:10%;
}
.item-name-box{
    max-width: 80%;
    margin: 0 auto;
}
.update-input-box{
    display: flex;
    flex-direction: rows;
    padding-top:50px;
    padding-bottom:50px;
    background-color:rgb(55, 139, 179);
    align-items: center;
    justify-content: center;
}

.cat-btn{
    margin:10px;
    font-size: 1.5rem;
}
.input-radio-box{
    background-color:rgb(231, 231, 219);
    margin-top:50px;
    padding:50px;
    h3{color:rgb(80, 70, 72)}
    .radio-choices{
        margin:50px;
    }
}
.edit-dropdown{
    margin: 0 auto;
    background-color:white;
}

.insert-input{
    max-width: 350px;
    margin:5px;
}

.dd-btn{
    min-width: 150px;
    background-color:rgb(80, 166, 236);
    font-size: 50px;
    margin-bottom:20px;
}
.dd-estimate-btn{
    min-width: 100px;
    font-size: 25px;
    margin:5px;
    background-color: aqua;
    color:black;
    border:0;
}

.dd-option{
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width:15px;
    display: flex;
    flex-direction: row;
}


.program-item-text{
    width:50%;
    font-size:25px;
    margin-right: 50px;
    background-color: transparent;
    color:blue;

}

.item-units-edit{
    margin: 0 auto;
    width: 65px;
    height:45px;
    padding:5px;
}
.program-name{
    margin: 0 auto;
    max-width: 50%;
}
.save-program-box{
    padding-bottom: 100px;
}
.save-program-btn{
    margin:10px;
    font-size: 1.5rem;
}

.cycles-box{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width:60%;
    padding-bottom:100px;
}
.cycles-card{
    background-color:rgb(62, 173, 218);
    margin: 0 auto;
}
.summary-box{
    padding:60px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    min-width:80%;
}
.summary-card{
    min-width: 50%;
    margin: 0 auto;
    background-color: red;
    background-color:rgb(238, 238, 245);
}

.summary-header{
    color:turquoise;
}
.modal-box{
    min-width: 300px;
    background-color: red;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-self:center;

}

.modal-card{
    background-color: black;
    min-width: 1400px;
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0 auto;
    margin-top:300px;
    align-self:center;
    justify-content: center;
}
.summary-items-box{
    margin-top:50px;
    display: flex;
    flex-direction: row;

}

.summary-items-list{
    padding:20px;
}

.card-header{
    color:rgb(46, 82, 93);
    padding:10px;
    background-color: white;
}

.cycles-title{
    margin-top:40px;
}
.summary-title{
    margin-top:10px;
}
.cycles-subtitle{
    padding:20px;
    margin-top:20px;
    color:white;
    h3{
        font-size: 25px;
        color:white;
    }
}

.print-box-bb{
    height:1500px;
}
.temp-save-box{
    background-color: red;
    position:relative;
}

.temporary-save{
    margin:15px;
    position:absolute;
    right:10px;
    top:10px;
    border:0;
    background-color:green;
}

//////// New Program Items

.program-variables-box{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 70%;
}

.program-variables-input{
    margin: 0 auto;
    max-width:100px;
}

.program-print-box{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
