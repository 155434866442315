
.search-user-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    padding:30px;
    background-color:transparent;
}
.search-user-1{
    display: flex;
    margin: 0 auto;
    min-width: 100%;
    flex-direction: row;
    background-color:transparent;
    padding:20px;
}

.switch-user-btn{
    background-color: aqua;
    color:black;
}

.search-user-input{
    max-width: 300px;
    margin:10px;
}

.search-user-db{
    max-width: 300px;
    margin:10px;
}

.search-user-2{
    display: flex;
    margin: 0 auto;
    min-width: 100%;
    flex-direction: row;
    background-color:rgb(226, 243, 233);
    padding:20px;
}



.searched-options{
    background-color:transparent;
    display: flex;
    margin: 0 auto;
    max-width: 100%;
}

.current-customer-box{
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    background-color: rgb(240, 240, 247);
    max-height: 150px;
}

.current-customer-input{
    margin-right:50px;
    color:black;
}

.customer-options{
    margin-top:20px;
    margin-right:20px;
    font-size:1.5rem;
}