.nav-bar{
    color:red;
    width: 100%;
    max-height: 500px;
    
}

.logo{
    margin:15px;
    max-width: 350px;
}


.nav-btn{
    width:300px;
    font-size: 35px;
    margin:5px;
}