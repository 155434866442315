$standard-button: 200px;
$standard-large-font: 25px; 
$standard-nav-btn: rgb(25, 87, 87);
$secondary-color: rgb(33, 163, 163);
$input-height: 60px;

/// Item Selection ///



.item-selection-div{
    display: flex;
    flex-direction: column;

    width:100%;
    margin: 0 auto;

    .select-dropdown-box{
        max-width: 100%;
        margin: 0 auto;
        .item-select-btn{
            background-color:white;
            color: $standard-nav-btn;
            margin:5px;
            font-size: $standard-large-font;
            border-color: $standard-nav-btn;
        }
    }
}

.select-type-category{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
    min-width: 60%;

    .select-type-radio{
        margin:30px;
        h3{
            font-size: $standard-large-font;
            color: $standard-nav-btn;
        }
    }

    .category-dropdown{
        justify-content: center;
        align-self: center;
    
        .dd-button{
            width: 300px;
            font-size: $standard-large-font;
            background-color: $standard-nav-btn;
            border:0;
        }
        .open-item-button{
            width: $standard-button;
            font-size: $standard-large-font;
            background-color: white;
            color: $standard-nav-btn;
            border-color:$standard-nav-btn;
            margin-left: 30px;
        }
    }
    
}

//////

/// Customer Search ///

.customer-box{
    background-color:white;
    min-width: 100%;
}

.search-user-2{
    background-color: white;
    .search-user-input{
        height: $input-height;
        font-size: $standard-large-font;
    }
}
.switch-box{
    display: flex;
    flex-direction: row;

    position:relative;
    height:70px;
    align-content: flex-end;
    white-space: nowrap;
    overflow: hidden;

    .switch-to-search{
        right:10px;
        top:10px;
        margin-right:10px;
        position:absolute;
        font-size: $standard-large-font;
        background-color:$secondary-color;
        border:0;
    }
}
.customer-current-box{
    display: flex;
    flex-direction: row;
    padding:25px;
    color: $standard-nav-btn;
    h4{
        padding-right:100px;
    }
    
}
.save-btn{
    min-width: $standard-button;
    margin-right:25px;
    background-color: $secondary-color;
    font-size: $standard-large-font;
    border:0;
}

.customer-options{
    background-color: $standard-nav-btn;
    border:0;
}

//// Estimate Items ///

.estimate-items-box{
    display: flex;
    flex-direction: row;
    width:100%;
}

.estimate-container{
    width:100%;
}
.estimate-row{
    height:150px;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
}

.col-name{
    margin: 0 auto;
    justify-content: center;
    .units-edit{
        height:$input-height;
        font-size: 25px;
    }
    .units-edit-m-disc{
        height:$input-height;
        font-size: 25px;
        min-width: 120px;
    }
    .units-edit-mon{
        height:$input-height;
        font-size: 25px;
        min-width: 120px;
    }
  
    .dd-estimate-btn{
        background-color:$standard-nav-btn;
        height: $input-height;
        color:white;
    }
}

.estimate-col-m-disc{
    color: $secondary-color;
    min-width: 120px;
    font-size: 25px;
    font-weight: 600;
}

.col-name-money{
    margin: 0 auto;
    justify-content: center;
    .units-edit{
        height:$input-height;
        font-size: 25px;
        min-width: 150px;
    }
  
}



.estimate-col-name{
    color: $secondary-color;
    min-width: 350px;
    font-size: 25px;
    font-weight: 600;
    .units-edit-open{
            height:$input-height;
            font-size: 25px;
            min-width: 120px;
      
    }
}
.estimate-col{
    color: $secondary-color;
    font-size: 25px;
    font-weight: 600;

}
.temp-save-box{
    background-color: aqua;
    .temporary-save{
        font-size: $standard-large-font;
        font-weight: 500;
        background-color: $secondary-color;
    }
    
}


.invoice-radio-box{

    padding:50px;
}

/// Admin ////

.admin-content{
    display: flex;
    flex-direction: column;
    width:100%;
}

.admin-input-box{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    width:100%;
}

.admin-input-header{
    color:black;
}
.admin-display-table{

    margin-top:200px;
    align-content: flex-start;
}

.admin-row{
    margin-top:20px;
}

.admin-modal-box{
    min-width: 300px;
    background-color: red;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-self:center;
    position:relative;
    .delete-btn{
        position:absolute;
        right:10px;
        top:10px;
        background-color:transparent;
        border-color:red;
        color:red;
    }
    .popup-cat-dropdown{
        display:flex;
        flex-direction: column;
        margin: 0 auto;
        align-content: center;
    }
    .popup-dd-button{
        margin: 0 auto;
        align-content: center;
        font-size: $standard-large-font;
    }
    .popup-dd-list{

        margin: 0 auto;
        position:absolute;
        left:300px;


    }

}

.admin-modal-card{
    min-width: 500px;
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0 auto;
    margin-top:300px;
    align-self:center;
    justify-content: center;
}
.popup-text{
    margin: 0 auto;
    max-width: 50%;
}



//// Program Items
.program-header{
    padding:20px;
}

.program-variables-box{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    background-color: $secondary-color;
    padding:20px;
}
.program-variables-input{
    margin: 0 auto;
    min-width:100px;
    font-size: $standard-large-font;
}


/// Estimate

.save-db-box{

    margin:0 auto;
    .save-to-db{
        width: $standard-button;
        font-size: $standard-large-font;
        background-color: $standard-nav-btn;
        border:0;

    }
}
.additional-remarks{
    margin: 0 auto;
    display: flex;
    flex-direction:column;
    margin: 20px;
    .remark-text{
        margin: 0 auto;
        max-width: 50%;
        padding:20px;
        font-size: $standard-large-font;
    }
}


/// Record ///

.record-main-box{
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }

//// RECORD //

.record-main-box{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width:100%;
  }
  
  .record-card-box{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 800px;
    position:relative;
    .invoice-or-est{
      position:absolute;
      left:20px;
      top:20px;
    }
    .perfect-id{
        position:absolute;
        left:20px;
        top:20px;
      }
      .typeof-rec{
          font-size: 30px;
      }
      .rec-date{
          position:absolute;
          top:20px;
          right:20px;
      }
      .action-content-rev{

          display: flex;
          margin: 0 auto;

          margin-top:100px;
      }
        .record-options-btn{
            margin:15px;
            font-size: $standard-large-font;
        }
  }