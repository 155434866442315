
$main-box-margin: 10px;
$title-margin: 20px;

.dashboard-main{

    display: flex;
    flex-direction: row;
    width:100%;

    .dash-col-1{

        display: flex;
        flex-direction: column;
        width:30%;
        margin:$main-box-margin;
    }
    .dash-col-2{

        display: flex;
        flex-direction: column;
        width:70%;

        margin:$main-box-margin;
    }

    .dash-card{
        margin:$main-box-margin;
        .dash-card-header{
            margin:$title-margin;
            font-size: 40px;
            background-color: rgb(206, 201, 201);
        }
    }

}