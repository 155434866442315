.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #454a55;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.main-box{
  background-color: white;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin:15px;
  font-size: calc(10px + 2vmin);
  background-color: rgb(130, 179, 224);
  align-items: center;
  justify-content: center;
}

.login-redirect{
  width:100%;
  flex-direction: column;
  margin: 0 auto;
}
.plain-text{
  color:black;
  padding-top:20px;
  h3{
    font-size:20px;


  }
}
.content-box{
  background-color: white;
  width:100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: rgb(105, 28, 28);
}
.nav-bar{

  max-width:350px;
  .logout-btn{
    background-color:red;
    border:0;
    font-size:30px;
  }
}
.current-action-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
} 



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.demo-btn{
  color:red;
}

.item-selection-box{
  display: flex;
  flex-direction: column;
  width:100%;
  margin: 0 auto;
}


